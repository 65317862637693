import { useEffect, useState } from "react";
import {
  GlobalCard,
  ModalComponent,
  useStyles,
} from "@mfe/js-common-ave-uiutils";
import {
  Box,
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { AiFillWarning } from "react-icons/ai";

import {
  MsalProvider,
  useIsAuthenticated,
  useMsal,
} from "@mfe/react-common-ave-msal";
import {
  es,
  fetchExpensesTypeData,
  fetchSentToApproveNovelty,
  fetchTraveLiquidatorByIDData,
  formatter,
  formatterNumberInput,
  getAccessTokenApi,
  moment,
  parseDateWihtFormat,
  parserNumberInput,
} from "@mfe/ts-common-ave-utilitaries";

import { navigateToUrl } from "single-spa";
import Justification from "../components/justification.component";
import ModifyDates from "../components/modifyDates.component";
import NewExpenses from "../components/newExpenses.component";

export default function Novelty({ organization, msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <NoveltyComponent organization={organization} />
    </MsalProvider>
  );
}
export const NoveltyComponent = ({ organization }) => {
  const { classes } = useStyles();
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [loading, setLoading] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [modalContent, setModalContent] = useState({
    content: "",
    onClick: () => {},
    closeOnClickOutside: true,
  });
  const [data, setData] = useState({
    travelLiquidatorData: {
      Id: "",
      Multiviaje: null,
      ViajeTrayecto: [],
      ViajeGasto: [],
      CodigoEmpleadoViajero: "",
      TipoViajeId: "",
      CodigoEmpleadoAprobador: "",
      NombreEmpleadoAprobador: "",
      CodigoEmpleadoaprobadorPrevio: "",
      ComisionId: "",
      NombreEmpleadoViajero: "",
      TipoViajeAlias: "",
    },
    travelExpensesType: [],
  });
  const [newExpenses, setNewExpenses] = useState([]);
  const [tokenApi, setTokenApi] = useState("");
  const [initialDates, setInitialDates] = useState([]);
  const [dates, setDates] = useState([]);
  const isAid = data?.travelLiquidatorData?.TipoViajeAlias === "TVAMBULATORIOS";
  const isTurn = data?.travelLiquidatorData?.TipoViajeAlias === "TVTURNOS";
  const form = useForm({
    initialValues: {
      dates: [new Date(), new Date()],
      newExpense: "",
      newExpenseValue: "",
      justification: "",
    },
    validate: {
      newExpense: (value) => (value.length ? null : "El gasto es obligatorio"),
      newExpenseValue: (value) =>
        value ? null : "El valor del gasto es obligatorio",
      justification: (value) =>
        value.length >= 10 ? null : "La justificación es obligatoria",
    },
  });

  useEffect(() => {
    getAccessTokenApi(instance, accounts, setTokenApi);
  }, []);

  useEffect(() => {
    if (tokenApi) {
      fetchTraveLiquidatorByIDData(
        setData,
        tokenApi,
        localStorage.getItem("liquidationID"),
        setLoading
      );
      fetchExpensesTypeData(setData, tokenApi);
    }
  }, [tokenApi]);

  useEffect(() => {
    if (data?.travelLiquidatorData?.ViajeTrayecto?.length > 0) {
      setInitialDates(
        data?.travelLiquidatorData?.ViajeTrayecto?.map((item) => [
          moment(item.FechaInicio).toDate(),
          moment(item.FechaFin).toDate(),
        ])
      );
      setDates(
        data?.travelLiquidatorData?.ViajeTrayecto?.map((item) => [null, null])
      );
    }
  }, [data?.travelLiquidatorData?.ViajeTrayecto?.length]);

  const handleAddExpense = () => {
    const newExpense = {
      tipoGasto: form.values.newExpense,
      valorFinal: form.values.newExpenseValue,
    };

    setNewExpenses((prev) => [...prev, newExpense]);

    form.setValues((prev) => ({
      ...prev,
      newExpense: "",
      newExpenseValue: "",
    }));
  };

  const validateThirdUserOrIsAid = (data, isAid) => {
    const regex = /^[0-9]+$/;
    return isAid ? isAid : isTurn ? !isTurn : !regex.test(data);
  };

  const handleDeleteExpense = (indexExpense) => {
    const newState = [...newExpenses];
    setNewExpenses(newState.filter((_, index) => index !== indexExpense));
  };

  const handleNavigate = (id) => {
    localStorage.setItem("liquidationID", id);
    navigateToUrl("/reserva-detalles");
  };

  const handleSendToApprove = async () => {
    const payload = {
      idViaje: data?.travelLiquidatorData?.Id,
      justificacion: form.values.justification,
      trayectos: data?.travelLiquidatorData?.ViajeTrayecto?.map(
        (item, index) => ({
          fkIdTrayecto: item.Id,
          fechaFinViaje: dates?.[index]?.[1]
            ? parseDateWihtFormat(dates?.[index]?.[1], "YYYY-MM-DD")
            : parseDateWihtFormat(initialDates?.[index]?.[1], "YYYY-MM-DD"),
        })
      ),
      gastos: newExpenses?.map((item) => ({
        fkIdOtroGasto: item.tipoGasto.split("/")[0],
        valor: item.valorFinal,
        moneda: "COP",
      })),
      codigoEmpleado: data?.travelLiquidatorData?.CodigoEmpleadoViajero,
      fkTipoViaje: String(data?.travelLiquidatorData?.TipoViajeId),
      numSegmento: 0,
      novedadBPO: {},
      idAprobador: data?.travelLiquidatorData?.CodigoEmpleadoAprobador,
      novedadesCharter: [],
    };
    try {
      setLoading(true);
      await fetchSentToApproveNovelty(tokenApi, payload);
      setModalContent({
        content: "La novedad ha sido creada exitosamente.",
        onClick: () => handleNavigate(payload.idViaje),
        closeOnClickOutside: false,
      });
      open();
    } catch (error) {
      setModalContent({
        content:
          "Ocurrió un error al enviar a aprobar la novedad, inténtelo de nuevo.",
        onClick: () => close(),
        closeOnClickOutside: true,
      });
      open();
    } finally {
      setLoading(false);
    }
  };

  const handleModifyEndDate = (value, index) => {
    const newState = [...dates];
    newState[index][1] = value;
    setDates(newState);
  };
  const utils = { es, formatter, formatterNumberInput, parserNumberInput };

  const newExpensesProps = {
    classes,
    form,
    GlobalCard,
    newExpenses,
    utils,
    handleAddExpense,
    data,
    handleDeleteExpense,
  };

  const modifyDatesProps = {
    classes,
    GlobalCard,
    dates,
    initialDates,
    setDates,
    utils,
    data,
    handleModifyEndDate,
  };

  const justificationProps = {
    classes,
    GlobalCard,
    form,
  };

  if (loading) {
    return (
      <LoadingOverlay
        visible={loading}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return isAuthenticated ? (
    <>
      <ModalComponent
        opened={opened}
        onClose={close}
        title="Alerta"
        closeOnClickOutside={modalContent.closeOnClickOutside}
      >
        <Text>{modalContent.content}</Text>
        <Group position="right" mt="md">
          <Button className={classes.button} onClick={modalContent.onClick}>
            Aceptar
          </Button>
        </Group>
      </ModalComponent>
      <Box maw={{ xs: "100%", md: "80%" }} mx="auto" pt={16} pb={32}>
        <Text role="heading" size={24} color="#004236" fw={700} component="h1">
          Crear novedad
        </Text>
        <GlobalCard
          styles={{
            backgroundColor: "#F8F8F8",
          }}
        >
          <Flex align="center" gap={8}>
            <AiFillWarning
              color="#004236"
              size="28px"
              style={{
                flexShrink: 0,
              }}
            />
            <Text size="md">
              Si usted va a realizar un cambio de fecha o adicionar nuevos
              gastos, complete la información. Tenga en cuenta que las novedades
              que generen un valor adicional de viáticos, le serán pagados
              cuando finalice la legalización. Recuerde subir los soportes
              adicionales de su viaje.
            </Text>
          </Flex>
        </GlobalCard>

        <Text color="#004236" size="xl" fw={500}>
          Modificar fechas final del viaje {data?.travelLiquidatorData?.Id}
        </Text>

        <ModifyDates {...modifyDatesProps} />
        {validateThirdUserOrIsAid(
          data?.travelLiquidatorData?.CodigoEmpleadoViajero,
          isAid
        ) && <NewExpenses {...newExpensesProps} />}

        <Text color="#004236" size="xl" fw={500}>
          Justificación de la novedad
        </Text>

        <Justification {...justificationProps} />

        <Text color="#004236" size="xl" fw={500}>
          Aprobador
        </Text>
        <GlobalCard>
          <TextInput
            classNames={{
              input: classes.input,
              label: classes.labelSecondary,
            }}
            label="Nombre del aprobador"
            value={data?.travelLiquidatorData?.NombreEmpleadoAprobador}
            disabled={true}
          />
        </GlobalCard>

        <Flex align="center" justify="flex-end" gap={12}>
          <Button
            className={classes.button}
            onClick={handleSendToApprove}
            disabled={!form.isValid("justification")}
          >
            Enviar aprobar
          </Button>
        </Flex>
      </Box>
    </>
  ) : null;
};
