import { TextInput, Grid } from "@mantine/core";

const Justification = ({ GlobalCard, classes, form }) => {
  return (
    <GlobalCard>
      <Grid>
        <Grid.Col>
          <TextInput
            {...form.getInputProps("justification")}
            classNames={{
              input: classes.input,
              label: classes.labelSecondary,
            }}
            label="Justificación"
            description="Por favor, ingrese una justificación superior a 10 caracteres"
            placeholder="Ingrese la justificación de la novedad"
            withAsterisk
          />
        </Grid.Col>
      </Grid>
    </GlobalCard>
  );
};

export default Justification;
