import {
  Text,
  Grid,
  Select,
  NumberInput,
  Flex,
  Button,
  ActionIcon,
} from "@mantine/core";
import { LuChevronDown } from "react-icons/lu";
import { FaTrashCan } from "react-icons/fa6";

const NewExpenses = ({
  classes,
  form,
  GlobalCard,
  newExpenses,
  utils,
  handleAddExpense,
  data,
  handleDeleteExpense,
}) => {
  return (
    <>
      <Text color="#004236" size="xl" fw={500}>
        Adicionar nuevos gastos
      </Text>
      <GlobalCard>
        <Grid align="center">
          <Grid.Col xs={12} md={6}>
            <Select
              {...form.getInputProps("newExpense")}
              rightSection={<LuChevronDown size="1rem" />}
              classNames={{
                input: classes.input,
                label: classes.labelSecondary,
                dropdown: classes.dropdown,
                rightSection: classes.rightSection,
                item: classes.item,
              }}
              label="Nuevo gasto"
              data={data.travelExpensesType}
            />
          </Grid.Col>
          <Grid.Col xs={12} md={6}>
            <NumberInput
              {...form.getInputProps("newExpenseValue")}
              parser={utils.parserNumberInput}
              formatter={utils.formatterNumberInput}
              min={0}
              classNames={{
                input: classes.input,
                label: classes.labelSecondary,
              }}
              hideControls
              label="Valor"
              disabled={form.values.newExpense.length === 0}
            />
          </Grid.Col>

          <Grid.Col xs={12} offsetMd={6} md={6}>
            <Flex align="flex-end" direction="column" gap={8}>
              <Text color="#004236">
                {`Por favor, recuerde que para agregar los gastos debe dar clic
                  en `}
                <Text span fw={500}>
                  Agregar
                </Text>
                .
              </Text>
              <Button
                onClick={handleAddExpense}
                className={classes.button}
                disabled={
                  !form.isValid("newExpense") ||
                  !form.isValid("newExpenseValue")
                }
                data-testid="add-button"
              >
                Agregar
              </Button>
            </Flex>
          </Grid.Col>
          {newExpenses.length > 0 && (
            <Grid.Col span={12}>
              <Grid columns={2}>
                <Grid.Col span={1}>
                  <Text color="#004236" fw={700}>
                    Gasto
                  </Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Text color="#004236" fw={700}>
                    Valor total
                  </Text>
                </Grid.Col>
                {newExpenses.map((item, index) => (
                  <Grid.Col span={2} key={index}>
                    <Grid columns={2}>
                      <Grid.Col span={1}>
                        <Text color="#004236">
                          {item.tipoGasto.split("/")[1]}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Flex gap={8}>
                          <Text color="#004236">
                            {utils.formatter.format(item.valorFinal)}
                          </Text>
                          <ActionIcon
                            onClick={() => handleDeleteExpense(index)}
                            ml="auto"
                            data-testid="delete-button"
                          >
                            <FaTrashCan color="#004236" />
                          </ActionIcon>
                        </Flex>
                      </Grid.Col>
                    </Grid>
                  </Grid.Col>
                ))}
              </Grid>
            </Grid.Col>
          )}
        </Grid>
      </GlobalCard>
    </>
  );
};

export default NewExpenses;
