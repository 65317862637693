import { Grid, TextInput } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";

const ModifyDates = ({
  GlobalCard,
  classes,
  initialDates,
  utils,
  dates,
  setDates,
  data,
  handleModifyEndDate,
}) => {
  const isMultiTravel = data?.travelLiquidatorData?.Multiviaje;
  const liquidatorData = data?.travelLiquidatorData?.ViajeTrayecto;
  const validateTravelData = isMultiTravel
    ? liquidatorData
    : [liquidatorData?.[0]];
  return validateTravelData?.map((item, index) => (
    <GlobalCard key={index}>
      <Grid align="center" columns={5}>
        <Grid.Col xs={5} md={1}>
          <TextInput
            value={item?.nombreCiudadOrigen}
            classNames={{
              input: classes.input,
              label: classes.labelSecondary,
            }}
            label="Ciudad de origen"
            disabled={true}
          />
        </Grid.Col>
        <Grid.Col xs={5} md={1}>
          <TextInput
            value={item?.nombreCiudadDestino}
            classNames={{
              input: classes.input,
              label: classes.labelSecondary,
            }}
            label="Ciudad de destino"
            disabled={true}
          />
        </Grid.Col>
        <Grid.Col xs={5} md={1}>
          <DatePickerInput
            value={initialDates?.[index]?.[0]}
            locale={utils.es}
            minDate={new Date()}
            label="Fecha inicial del viaje"
            classNames={{
              input: classes.input,
              label: classes.labelSecondary,
              day: classes.calendar,
            }}
            valueFormat="DD/MMM/YY"
            disabled={true}
          />
        </Grid.Col>
        <Grid.Col xs={5} md={1}>
          <DatePickerInput
            value={initialDates?.[index]?.[1]}
            locale={utils.es}
            minDate={new Date()}
            label="Fecha final del viaje"
            classNames={{
              input: classes.input,
              label: classes.labelSecondary,
              day: classes.calendar,
            }}
            valueFormat="DD/MMM/YY"
            disabled={true}
          />
        </Grid.Col>
        <Grid.Col xs={5} md={1} data-testid="modify-date">
          <DatePickerInput
            value={dates?.[index]?.[1]}
            onChange={(value) => handleModifyEndDate(value, index)}
            locale={utils.es}
            minDate={initialDates?.[index]?.[0]}
            label="Fecha final a modificar"
            classNames={{
              input: classes.input,
              label: classes.labelSecondary,
              day: classes.calendar,
            }}
            valueFormat="DD/MMM/YY"
            clearable
          />
        </Grid.Col>
      </Grid>
    </GlobalCard>
  ));
};

export default ModifyDates;
